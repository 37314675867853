var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"xl":"6"}},[_c('b-card',[_c('b-card-title',{staticClass:"mb-Login"},[_vm._v(" User Details ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"First Name","label-for":"vi-first-name"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"vi-first-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-first-name","type":"text","placeholder":"First Name"},on:{"change":_vm.handleForm},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Last Name","label-for":"vi-last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"vi-last-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-last-name","type":"text","placeholder":"Last Name"},on:{"change":_vm.handleForm},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Address Line 1","label-for":"vi-address-line1"}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"vi-address-line1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-address-line1","type":"text","placeholder":"Address Line 1"},on:{"change":_vm.handleForm},model:{value:(_vm.form.address1),callback:function ($$v) {_vm.$set(_vm.form, "address1", $$v)},expression:"form.address1"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Address Line 2","label-for":"vi-address-line2"}},[_c('validation-provider',{attrs:{"name":"Address Line2","vid":"vi-address-line2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-address-line2","type":"text","placeholder":"Address Line 2"},on:{"change":_vm.handleForm},model:{value:(_vm.form.address2),callback:function ($$v) {_vm.$set(_vm.form, "address2", $$v)},expression:"form.address2"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Email","label-for":"vi-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"vi-email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-email","type":"email","placeholder":"Email"},on:{"change":_vm.handleForm},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Mobile","label-for":"vi-mobile"}},[_c('validation-provider',{attrs:{"name":"Mobile","vid":"vi-mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SmartphoneIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-mobile","type":"number","placeholder":"Mobile"},on:{"change":_vm.handleForm},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"City","label-for":"vi-city"}},[_c('validation-provider',{attrs:{"name":"City","vid":"vi-city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{ref:"city_name",class:errors[0] ? 'border-red-selector' : '',attrs:{"suggestions":_vm.cityOptions,"input-props":{id: 'autosuggest__input_ajax',
                             placeholder: 'City',
                             class: 'form-control',},"render-suggestion":_vm.renderSuggestion,"get-suggestion-value":_vm.renderSuggestion},on:{"selected":_vm.change_autosuggest,"input":_vm.suggestForm},model:{value:(_vm.form.city_name),callback:function ($$v) {_vm.$set(_vm.form, "city_name", $$v)},expression:"form.city_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Zip","label-for":"vi-zip"}},[_c('validation-provider',{attrs:{"name":"Zip","vid":"vi-zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                             var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapPinIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-zip","type":"text","placeholder":"Zip"},on:{"change":_vm.handleForm},model:{value:(_vm.form.zip),callback:function ($$v) {_vm.$set(_vm.form, "zip", $$v)},expression:"form.zip"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Status","label-for":"vi-status"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"vi-status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                             var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","reduce":function (op) { return op.value; },"placeholder":"Status","options":_vm.statusOptions},on:{"input":_vm.handleForm},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }