var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"xl":"6"}},[_c('b-card',[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Login Details ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Username","label-for":"vi-user-name"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"vi-user-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-user-name","placeholder":"Username"},on:{"change":_vm.handleForm},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Employee Code","label-for":"vi-employee-code"}},[_c('validation-provider',{attrs:{"name":"Employee Code","vid":"vi-employee-code","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-employee-code","placeholder":"Employee Code"},on:{"change":_vm.handleForm},model:{value:(_vm.form.employee_code),callback:function ($$v) {_vm.$set(_vm.form, "employee_code", $$v)},expression:"form.employee_code"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('b-row',{staticClass:"px-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{class:_vm.req ? 'required' : '',attrs:{"label":"OTP Login","label-for":"vi-otp-login"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},on:{"change":_vm.handleForm},model:{value:(_vm.form.otp_login),callback:function ($$v) {_vm.$set(_vm.form, "otp_login", $$v)},expression:"form.otp_login"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Face Login","label-for":"vi-face-login"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"face-login","switch":"","inline":""},on:{"change":_vm.handleForm},model:{value:(_vm.form.face_login),callback:function ($$v) {_vm.$set(_vm.form, "face_login", $$v)},expression:"form.face_login"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{class:_vm.req ? 'required' : '',attrs:{"label":"Password","label-for":"vi-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"vi-password","rules":_vm.req ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-password","type":_vm.passwordFieldType,"placeholder":"Password"},on:{"change":_vm.handleForm},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{class:_vm.req ? 'required' : '',attrs:{"label":"Confirm Password","label-for":"vi-password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","vid":"vi-confirm-password","rules":_vm.req ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-password","type":_vm.passwordFieldType,"placeholder":"Password"},on:{"change":_vm.handleForm},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"User Type","label-for":"vi-user-type"}},[_c('validation-provider',{attrs:{"name":"User Type","vid":"vi-user-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"group_desc","placeholder":"Select User Type","reduce":function (ut) { return ut.group_id; },"options":_vm.userTypes},on:{"change":_vm.handleForm},model:{value:(_vm.form.user_type),callback:function ($$v) {_vm.$set(_vm.form, "user_type", $$v)},expression:"form.user_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{class:_vm.pic_required,attrs:{"label":"Profile Photo","label-for":"vi-profile-photo"}},[(_vm.form.profile_photo)?_c('div',{attrs:{"id":"profile_photo_preview"}},[_c('img',{attrs:{"src":_vm.form.profile_photo,"max-height":"220","alt":""}})]):_vm._e(),_c('validation-provider',{attrs:{"name":"Profile Photo","vid":"vi-profile-photo","rules":_vm.pic_required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-file',{attrs:{"id":"vi-profile-photo","accept":"image/*","type":"file"},on:{"change":_vm.getprofile},model:{value:(_vm.form.profile_pic),callback:function ($$v) {_vm.$set(_vm.form, "profile_pic", $$v)},expression:"form.profile_pic"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }